import React from "react";
import PropTypes from "prop-types";

import "./privacy-policy.css";

const PrivacyPolicy = ({
  data: { policy_headline: headline, policy_content: content }
}) => (
  <section className="privacy-policy">
    <div className="container">
      <h1 className="privacy-policy__headline">{headline.text}</h1>
      <div
        className="privacy-policy__content"
        dangerouslySetInnerHTML={{ __html: content.html }}
      />
    </div>
  </section>
);

PrivacyPolicy.propTypes = {
  data: PropTypes.shape({
    policy_headline: PropTypes.object.isRequired,
    policy_content: PropTypes.object.isRequired
  }).isRequired
};

export default PrivacyPolicy;
