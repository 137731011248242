import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Header from "../components/Header";
import PrivacyPolicy from "../components/PrivacyPolicy";
import LocalizedLink from "../components/LocalizedLink";

const PolicyPage = ({ data: { policy }, pageContext: { locale } }) => (
  <main>
    <Header>
      <LocalizedLink to="/">
        {locale === "fr-fr" ? "Accueil" : "Home"}
      </LocalizedLink>
    </Header>
    <PrivacyPolicy data={policy.data} />
  </main>
);

PolicyPage.propTypes = {
  data: PropTypes.shape({
    policy: PropTypes.object.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string
  })
};

export const pageQuery = graphql`
  query PolicyQuery($locale: String!) {
    policy: prismicPrivacyPolicy(lang: { eq: $locale }) {
      data {
        policy_headline {
          text
        }
        policy_content {
          html
        }
      }
    }
  }
`;

export default PolicyPage;
